/*
Copyright 2021 ChainSafe Systems
SPDX-License-Identifier: LGPL-3.0-only
*/
import React from "react"

function ToolTipIcon(props: any) {
  return (
    <svg
      width="15"
      viewBox="0 0 15 15"
      fill="currentColor"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 0.9375C3.87598 0.9375 0.9375 3.87598 0.9375 7.5C0.9375 11.124 3.87598 14.0625 7.5 14.0625C11.124 14.0625 14.0625 11.124 14.0625 7.5C14.0625 3.87598 11.124 0.9375 7.5 0.9375ZM7.5 12.9492C4.49121 12.9492 2.05078 10.5088 2.05078 7.5C2.05078 4.49121 4.49121 2.05078 7.5 2.05078C10.5088 2.05078 12.9492 4.49121 12.9492 7.5C12.9492 10.5088 10.5088 12.9492 7.5 12.9492Z"
        fill="currentColor"
      />
      <path
        d="M9.13477 4.63916C8.69531 4.25391 8.11523 4.04297 7.5 4.04297C6.88477 4.04297 6.30469 4.25537 5.86523 4.63916C5.4082 5.03906 5.15625 5.57666 5.15625 6.15234V6.26367C5.15625 6.32812 5.20898 6.38086 5.27344 6.38086H5.97656C6.04102 6.38086 6.09375 6.32812 6.09375 6.26367V6.15234C6.09375 5.50635 6.7251 4.98047 7.5 4.98047C8.2749 4.98047 8.90625 5.50635 8.90625 6.15234C8.90625 6.60791 8.58398 7.02539 8.08447 7.21729C7.77393 7.33594 7.51025 7.54394 7.32129 7.81641C7.12939 8.09473 7.02979 8.42871 7.02979 8.76709V9.08203C7.02979 9.14648 7.08252 9.19922 7.14697 9.19922H7.8501C7.91455 9.19922 7.96729 9.14648 7.96729 9.08203V8.74951C7.96804 8.60731 8.01163 8.46863 8.09238 8.35157C8.17312 8.2345 8.28726 8.14449 8.41992 8.09326C9.28418 7.76074 9.84229 6.99902 9.84229 6.15234C9.84375 5.57666 9.5918 5.03906 9.13477 4.63916ZM6.91406 10.7227C6.91406 10.8781 6.9758 11.0271 7.08568 11.137C7.19556 11.2469 7.3446 11.3086 7.5 11.3086C7.6554 11.3086 7.80444 11.2469 7.91432 11.137C8.02421 11.0271 8.08594 10.8781 8.08594 10.7227C8.08594 10.5673 8.02421 10.4182 7.91432 10.3083C7.80444 10.1985 7.6554 10.1367 7.5 10.1367C7.3446 10.1367 7.19556 10.1985 7.08568 10.3083C6.9758 10.4182 6.91406 10.5673 6.91406 10.7227Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ToolTipIcon
